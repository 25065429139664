@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700&display=swap');

@import './dependency-overrides.scss';
@import './page-template.scss';
@import './variables.scss';


/* Generic.
-------------------------------------------------- */
a {
  background: 0 0;
  color: $blue;
  margin: 0 3px;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  background-color: $gray-100;
  border: none;
  border-radius: 3px;
  color: $gray-600;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 10px 20px;
}

button:focus {
  outline: 0;
}

button:hover {
  background-color: $gray-200;
  color: rgba(0,0,0,.8);
  cursor: pointer;
}

.big-icon {
  font-size: 1.8rem;
  margin-right: 10px;
}

.icon-left {
  margin-right: 5px;
}

.icon-right {
  margin-left: 5px;
}

h1 {
  font-size: 1.9rem;
  font-weight: 600;
}

h2 {
  font-size: 1.6rem;
  font-weight: 600;
}

h3 {
  font-size: 1rem;
  font-weight: 600;
}

p {
  font-size: 1.2rem;
  line-height: 1.8rem;
}


/* Bio component.
-------------------------------------------------- */
.bio {
  display: flex;
  min-height: 300px;

  .left {
    align-self: center;
    padding-right: 40px;
    width: 60%;

    @media screen and (max-width: $medium-screen) {
      width: 100%;
    }

    .section {

      &:first-child {
        margin-bottom: 50px;
      }

      .title {
        align-items: center;
        display: flex;
        flex-direction: row;
      }
    }
  }

  .right {
    align-self: center;
    display: flex;
    justify-content: center;
    padding-left: 40px;
    width: 40%;

    @media screen and (max-width: $medium-screen) {
      display: none;
    }

    .portrait {
      border-radius: 10px;
      height: 100%;
      width: 100%;
    }
  }
}


/* Contact component.
-------------------------------------------------- */
.contact {
  border-top: 1px solid $gray-100;
}


/* Downloads component.
-------------------------------------------------- */
.downloads {
  align-items: center;
  border-bottom: 1px solid $gray-100;
  display: flex;
  flex-direction: column;

  a {
    color: $gray-600;
    transition: color 250ms linear;
  }

  a:hover {
    color: $green;
    transition: color 250ms linear;
  }

  svg {
    font-size: 4rem;
  }
}


/* Footer component.
-------------------------------------------------- */
.footer {
  align-items: center;
  background-color: $gray-600;
  color: $white;
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;

  .logo {
    height: 30px;
  }
}


/* Header component.
-------------------------------------------------- */
.header {

  .hero-content {
    display: flex;
    flex-direction: column;
    position: relative;

    .banner {
      height: 50%;
    }

    .sub-text {
      color: $white;
      font-size: 1.6rem;
    }
  }
}


/* Portfolio component.
-------------------------------------------------- */
.portfolio {

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .portfolio-card {
      border: 1px solid $gray-100;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      margin: 10px;
      max-width: 300px;
      overflow: hidden;
      width: calc((100% / 3) - 25px);

      @media screen and (max-width: $medium-screen) {
        width: calc((100% / 2) - 25px);
      }

      @media screen and (max-width: $small-screen) {
        max-width: 100%;
        width: 100%;
      }

      .image {
        border-bottom: 1px solid $gray-100;

        img {
          width: 100%;
        }
      }

      .content {
        border-bottom: 1px solid $gray-100;
        flex-grow: 1;
        padding: 15px;

        &:last-child {
          border-bottom: none;
        }

        &.extra {
          flex-grow: 0;
        }

        .title {
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .sub-title {
          color: $gray-300;
          margin-bottom: 10px;
        }

        .paragraph {
          color: $gray-500;
        }

        .date {
          color: $gray-300;
        }
      }
    }
  }
}


/* Social component.
-------------------------------------------------- */
.social {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: $medium-screen) {
    flex-direction: column;
  }

  .item {
    align-items: center;
    border: 1px solid $gray-100;
    border-left: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;

    @media screen and (max-width: $medium-screen) {
      border-bottom: none;
    }

    &:last-child {
      border-right: none;

      @media screen and (max-width: $medium-screen) {
        border-bottom: 1px solid $gray-100;
      }
    }

    .top {
      align-items: center;
      display: flex;
    }
  }
}
