@import './variables.scss';

/* Page template.
-------------------------------------------------- */
html {
  min-height: 100%;
  position: relative;
}

body {
  color: $gray-600;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0;

  .segment {
    padding: 8rem 14rem;

    @media screen and (max-width: $x-large-screen) {
      padding: 6rem 10rem;
    }

    @media screen and (max-width: $large-screen) {
      padding: 4rem 6rem;
    }

    @media screen and (max-width: $medium-screen) {
      padding: 4rem 2rem;
    }
  }
}


/* Scrollbar.
 ------------------------------------------------- */
::-webkit-scrollbar {
  background-color: $gray-000;
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-200;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $gray-300;
}

::-webkit-scrollbar-track {
  background-color: $gray-000;
}


/* Selection.
 ------------------------------------------------- */
::selection {
  background: $primary;
}
