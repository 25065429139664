/* Colors.
 ------------------------------------------------- */
$white:    #FFFFFF !default;
$gray-000: #F5F5F5 !default;
$gray-100: #E0E1E2 !default;
$gray-200: #CACBCD !default;
$gray-300: #ACACAC !default;
$gray-400: #757576 !default;
$gray-500: #5C5C5C !default;
$gray-600: #252525 !default;
$black:    #000000 !default;

$blue:     #4183C4 !default;
$green:    #42C17E !default;

$primary:  $green !default;


/* Media.
 ------------------------------------------------- */
$small-screen:   750px !default;
$medium-screen:  1000px !default;
$large-screen:   1200px !default;
$x-large-screen: 1400px !default;
