@import './variables.scss';


/* React Lazy Hero.
 *
 * Documentation can be found here:
 * https://github.com/danistefanovic/react-lazy-hero
 ------------------------------------------------- */
.lazy-hero {

  .banner {
    @media screen and (max-width: 1000px) {
      height: auto;
      width: 100%;
    }
  }
}


/* React Scroll Up Button.
 *
 * Documentation can be found here:
 * https://github.com/dirtyredz/react-scroll-up-button
 ------------------------------------------------- */
.scroll-up-button {
  border-radius: 5px;
  height: 40px !important;
  padding: 10px;
  width: 40px !important;

  &:active, &:focus {
    outline: none;
  }

  &:hover {
    background-color: $green !important;
  }
}
